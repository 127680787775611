var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4"},[_c('h2',[_vm._v(_vm._s(this.term))]),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.userConf.date_range.startDate))+" - "+_vm._s(_vm._f("moment")(_vm.userConf.date_range.endDate))+" ")])]),_c('div',{staticClass:"col-xl-8 "},[_c('UsStatsConfiguration')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header h-auto border-0"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("analytics.trending_searches.item.timeline.title"))+" ")])]),_c('div',{staticClass:"card-toolbar"},[_c('span',{staticClass:"font-weight-bolder font-size-h3",class:this.search_history_item.table.length > 0
                  ? 'text-primary'
                  : 'text-danger'},[_c('i',{staticClass:"fa fa-search font-weight-bolder font-size-h3 mr-2",class:this.search_history_item.table.length > 0
                    ? 'text-primary'
                    : 'text-danger'}),_vm._v(" "+_vm._s(parseInt(_vm.search_history_item.queries).toLocaleString())+" / "),_c('i',{staticClass:"fa fa-users font-weight-bolder font-size-h3 mr-2",class:this.search_history_item.table.length > 0
                    ? 'text-primary'
                    : 'text-danger'}),_vm._v(" "+_vm._s(parseInt(_vm.search_history_item.unique_queries).toLocaleString())+" ")])])]),_c('div',{staticClass:"card-body p-0"},[(this.loading)?_c('div',[_c('svg',{staticStyle:{"margin":"auto","background":"#fff","display":"block"},attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"200px","height":"200px","viewBox":"0 0 100 100","preserveAspectRatio":"xMidYMid"}},[_c('g',{attrs:{"transform":"translate(20 50)"}},[_c('circle',{attrs:{"cx":"0","cy":"0","r":"6","fill":"#7b0000"}},[_c('animateTransform',{attrs:{"attributeName":"transform","type":"scale","begin":"-0.375s","calcMode":"spline","keySplines":"0.3 0 0.7 1;0.3 0 0.7 1","values":"0;1;0","keyTimes":"0;0.5;1","dur":"1s","repeatCount":"indefinite"}})],1)]),_c('g',{attrs:{"transform":"translate(40 50)"}},[_c('circle',{attrs:{"cx":"0","cy":"0","r":"6","fill":"#c34c37"}},[_c('animateTransform',{attrs:{"attributeName":"transform","type":"scale","begin":"-0.25s","calcMode":"spline","keySplines":"0.3 0 0.7 1;0.3 0 0.7 1","values":"0;1;0","keyTimes":"0;0.5;1","dur":"1s","repeatCount":"indefinite"}})],1)]),_c('g',{attrs:{"transform":"translate(60 50)"}},[_c('circle',{attrs:{"cx":"0","cy":"0","r":"6","fill":"#fd7f64"}},[_c('animateTransform',{attrs:{"attributeName":"transform","type":"scale","begin":"-0.125s","calcMode":"spline","keySplines":"0.3 0 0.7 1;0.3 0 0.7 1","values":"0;1;0","keyTimes":"0;0.5;1","dur":"1s","repeatCount":"indefinite"}})],1)]),_c('g',{attrs:{"transform":"translate(80 50)"}},[_c('circle',{attrs:{"cx":"0","cy":"0","r":"6","fill":"#ffbea0"}},[_c('animateTransform',{attrs:{"attributeName":"transform","type":"scale","begin":"0s","calcMode":"spline","keySplines":"0.3 0 0.7 1;0.3 0 0.7 1","values":"0;1;0","keyTimes":"0;0.5;1","dur":"1s","repeatCount":"indefinite"}})],1)])])]):(
              _vm.graphData && _vm.graphData[0].data && _vm.graphData[0].data.length > 0
            )?_c('div',[_c('apexchart',{ref:"chartItem",staticClass:"card-rounded-bottom",attrs:{"id":"chartItem","options":_vm.chartOptions,"series":_vm.graphData,"type":"area","height":"180","width":"100%"}})],1):_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between card-spacer flex-grow-1"},[_c('div',{staticClass:"card-body text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("analytics.searches_without_results.no_result"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "analytics.searches_without_results.no_result_subtitle" ))+" ")])])])])])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header flex-wrap py-3"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("analytics.trending_searches.item.table.title"))+" ")])]),_c('div',{staticClass:"card-toolbar"})]),_c('div',{staticClass:"card-body"},[(_vm.search_history_item.table.length > 0)?_c('div',{staticClass:"dataTables_wrapper dt-bootstrap4 no-footer",attrs:{"id":"kt_datatable_wrapper"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('v-data-table',{staticClass:"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",attrs:{"dense":"","headers":_vm.headers,"items":_vm.search_history_item.table,"options":_vm.options,"loading":_vm.loading,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.term",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                        name: 'trending-item',
                        query: { id: item.elastic_item_id }
                      }}},[_vm._v(" "+_vm._s(item.term)+" ")])]}},{key:"item.result_setting_type_id",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.result_setting_type_id == 1 ? "Autocomplete" : "Search")+" ")]}},{key:"item.queries",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.queries).toLocaleString())+" ")]}},{key:"item.unique_queries",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.unique_queries).toLocaleString())+" ")]}},{key:"item.avg_position",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.avg_position).toLocaleString("cs-CZ", { maximumFractionDigits: 2 }))+" ")]}},{key:"top",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex align-items-center position-relative my-1"},[_c('span',{staticClass:"svg-icon svg-icon-1 position-absolute ms-6"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none"}},[_c('rect',{attrs:{"opacity":"0.5","x":"17.0365","y":"15.1223","width":"8.15546","height":"2","rx":"1","transform":"rotate(45 17.0365 15.1223)","fill":"black"}}),_c('path',{attrs:{"d":"M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z","fill":"black"}})])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-solid w-250px ps-14",attrs:{"type":"text","placeholder":_vm.$t(
                              'analytics.trending_searches.item.table.search_field'
                            )},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('div',{staticClass:"d-flex align-items-center position-relative my-1 ml-2"},[_c('v-chip-group',{staticClass:"py-2",attrs:{"multiple":""},model:{value:(_vm.activeFilters.object_type),callback:function ($$v) {_vm.$set(_vm.activeFilters, "object_type", $$v)},expression:"activeFilters.object_type"}},[_vm._l((_vm.filters.object_type),function(item){return [_c('v-chip',{key:("" + item),attrs:{"value":item,"filter":_vm.activeFilters.object_type.includes(item),"ripple":false}},[_vm._v(" "+_vm._s(item)+" ")])]})],2)],1)])]},proxy:true}],null,false,2724267224)})],1)])]):_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h2',{staticClass:"m-5"},[_vm._v(" "+_vm._s(_vm.$t("analytics.trending_searches.item.no_result"))+" ")]),(
                    !_vm.loading && this.search_history_item.fixits.length === 0
                  )?_c('fixit_add_dialog',{attrs:{"term":this.term,"disabled":"true","callback":_vm.reloadData,"buttontitle":_vm.$t('search_settings.fixit.buttons.fix_this_problem')}}):_vm._e()],1)])])])])])]),(this.search_history_item.fixits.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header flex-wrap py-3"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.$t("analytics.trending_searches.item.table_fixit.title"))+" ")])]),_c('div',{staticClass:"card-toolbar"},[_c('fixit_edit_dialog',{attrs:{"disabled":"true","callback":_vm.reloadData}})],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('v-data-table',{staticClass:"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer",attrs:{"dense":"","headers":_vm.fixit_headers,"items":this.search_history_item.fixits,"options":_vm.options,"loading":_vm.loading,"search":_vm.search,"loading-text":_vm.$t('analytics.trending_searches.item.table_fixit.loading')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.term",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"fixit-steps"},[_c('span',{staticClass:"btn btn-danger text-left btn-arrow btn-arrow-right"},[_vm._v(_vm._s(_vm.$t("search_settings.fixit.from_this_query"))),_c('br'),_c('strong',[_vm._v(_vm._s(item.term))])]),_c('span',{staticClass:"btn btn-success text-left btn-arrow btn-arrow-right"},[_vm._v(_vm._s(_vm.$t("search_settings.fixit.to_query"))),_c('br'),_c('strong',[_vm._v(_vm._s(item.catalog_term))])])])]}},{key:"item.executed",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.executed))])]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('b-dropdown',{attrs:{"text":"Actions"}},[_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t("common.edit"))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t("common.delete"))+" ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" "+_vm._s(_vm.$t("common.reset"))+" ")])]},proxy:true}],null,false,3981912565)})],1)])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }